<template>
  <div class="freighth5-page">
    <div class="tab">
      <div :class="{ active: active == 0 }" @click="handleTab(0)">
        铁路运价试算
        <div class="tab-line" v-if="active == 0"></div>
      </div>
      <div :class="{ active: active == 1 }" @click="handleTab(1)">
        公路运价试算
        <div class="tab-line" v-if="active == 1"></div>
      </div>
    </div>
    <div class="Railway-contant" v-if="active == 0">
      <div
        class="cell"
        @click="handleCheckCell('railwayForm', 'develop', 'railwayList')"
      >
        <div class="label">发局</div>
        <div class="value">
          <span
            >{{ railwayForm.develop ? railwayForm.develop : "请选择" }}
          </span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div
        class="cell"
        @click="handleCheckCell('railwayForm', 'developStation', 'railwayList')"
      >
        <div class="label">发站</div>
        <div class="value">
          <span>{{
            railwayForm.developStation ? railwayForm.developStation : "请选择"
          }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div
        class="cell"
        @click="handleCheckCell('railwayForm', 'destination', 'railwayList')"
      >
        <div class="label">到局</div>
        <div class="value">
          <span>{{
            railwayForm.destination ? railwayForm.destination : "请选择"
          }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div
        class="cell"
        @click="
          handleCheckCell('railwayForm', 'destinationStation', 'railwayList')
        "
      >
        <div class="label">到站</div>
        <div class="value">
          <span>{{
            railwayForm.destinationStation
              ? railwayForm.destinationStation
              : "请选择"
          }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="a-link">
        <a href="https://ec.95306.cn/infoDetail-yfcx">找不到发站/到站？</a>
      </div>
    </div>
    <div class="Highway-contant" v-if="active == 1">
      <div
        class="cell"
        @click="handleCheckCell('highwayForm', 'develop', 'highwayList')"
      >
        <div class="label">始发地</div>
        <div class="value">
          <span>{{
            highwayForm.develop ? highwayForm.develop : "请选择"
          }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div
        class="cell"
        @click="handleCheckCell('highwayForm', 'developStation', 'highwayList')"
      >
        <div class="label">起点</div>
        <div class="value">
          <span>{{
            highwayForm.developStation ? highwayForm.developStation : "请选择"
          }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div
        class="cell"
        @click="handleCheckCell('highwayForm', 'destination', 'highwayList')"
      >
        <div class="label">目的地</div>
        <div class="value">
          <span>{{
            highwayForm.destination ? highwayForm.destination : "请选择"
          }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div
        class="cell"
        @click="
          handleCheckCell('highwayForm', 'destinationStation', 'highwayList')
        "
      >
        <div class="label">终点</div>
        <div class="value">
          <span>{{
            highwayForm.destinationStation
              ? highwayForm.destinationStation
              : "请选择"
          }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="search-btn" @click="handleSearch">查询</div>
    <div class="tips">
      <div class="tips-title">运费说明</div>
      <span
        >查询结果仅供参考，查询线路仅为重点线路<br />
        若查询不到请拨打客服电话：0902-2305929</span
      >
    </div>
    <el-drawer
      modal
      :append-to-body="true"
      :visible.sync="drawer"
      direction="btt"
      :before-close="handleClose"
    >
      <div class="freighth5-drawer-content">
        <ul>
          <li
            :class="{ activeli: item.label == computedKey }"
            v-for="(item, index) in options"
            :key="index"
            @click="handleClick(item)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { railwayList, railwayCalcd, highwayCalcd } from "@/api/hy.js";
export default {
  name: "freighth5",
  data() {
    return {
      active: 0,
      drawer: false,
      railwayForm: {
        develop: "",
        developStation: "",
        destination: "",
        destinationStation: "",
      },
      highwayForm: {
        develop: "",
        developStation: "",
        destination: "",
        destinationStation: "",
      },
      railwayList: {
        start: [],
        end: [],
      },
      highwayList: {
        start: [],
        end: [],
      },
      options: [],
      currentForm: "",
      currentKey: "",
    };
  },
  watch: {
    active: {
      handler() {
        console.log(this.active);
      },
      immediate: true,
    },
  },
  created() {
    this.WayList({ type: 1 });
    this.WayList({ type: 2 });
  },
  computed: {
    computedKey() {
      return this[this.currentForm][this.currentKey];
    },
  },
  methods: {
    handleSearch() {
      if (this.active == 0) {
        let route = Object.keys(this.railwayForm).find(
          (item) => !this.railwayForm[item]
        );
        if (route) {
          let msg = "";
          switch (route) {
            case "develop":
              msg = "请选择发局";
              break;

            case "developStation":
              msg = "请选择发站";
              break;

            case "destination":
              msg = "请选择到局";
              break;

            case "destinationStation":
              msg = "请选择到站";
              break;

            default:
              break;
          }
          this.$message.error(msg);
          return;
        }
      } else {
        let route = Object.keys(this.highwayForm).find(
          (item) => !this.highwayForm[item]
        );
        if (route) {
          let msg = "";
          switch (route) {
            case "develop":
              msg = "请选择始发地";
              break;

            case "developStation":
              msg = "请选择起点";
              break;

            case "destination":
              msg = "请选择目的地";
              break;

            case "destinationStation":
              msg = "请选择终点";
              break;

            default:
              break;
          }
          this.$message.error(msg);
          return;
        }
      }
      let obj = this.active == 0 ? this.railwayForm : this.highwayForm;
      this.$router.push({
        path: "/freighth5details",
        query: {
          type: this.active,
          ...obj,
        },
      });
    },
    handleClick(obj) {
      this[this.currentForm][this.currentKey] = obj.value;
      this.drawer = false;
    },
    WayList(obj) {
      railwayList(obj)
        .then((ret) => {
          if (ret.code === 0) {
            if (obj.type == 1) {
              console.log(this.highwayList);
              this.highwayList.start =
                ret.data.find((item) => item.name === "start")?.children || [];

              this.highwayList.end =
                ret.data.find((item) => item.name === "end")?.children || [];
            }
            if (obj.type == 2) {
              this.railwayList.start =
                ret.data.find((item) => item.name === "start")?.children || [];

              this.railwayList.end =
                ret.data.find((item) => item.name === "end")?.children || [];
            }
          }
        })
        .catch((err) => {});
    },
    handleCheckCell(formType, key, option) {
      this.currentForm = formType;
      this.currentKey = key;
      //   this.options = this[option].end[1].children;
      this.formatOptions(option);
      this.drawer = true;
    },
    formatOptions(option) {
      if (this.currentKey === "develop") {
        this.options = this[option].start;
      } else if (this.currentKey === "developStation") {
        this.options =
          this[option].start.find(
            (item) => item.value === this[this.currentForm].develop
          )?.children || [];
      } else if (this.currentKey === "destination") {
        this.options = this[option].end;
      } else if (this.currentKey === "destinationStation") {
        this.options =
          this[option].end.find(
            (item) => item.value === this[this.currentForm].destination
          )?.children || [];
      }
    },
    handleClose() {
      this.drawer = false;
    },
    handleTab(val) {
      this.active = val;
    },
  },
};
</script>
<style lang="scss">
.freighth5-drawer-content {
  height: 100%;
  width: 100%;
  padding: 20px;
  font-size: 26px;
  overflow: hidden;
  ul {
    height: 100%;
    overflow-y: auto;
    li {
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 10px 0;
    }
    .activeli {
      font-size: 28px;
      color: #409eff;
    }
  }
}
</style>
<style lang="scss" scoped>
.freighth5-page {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .tab {
    display: flex;
    align-items: flex-start;
    height: 88px;
    width: 100%;
    border: 1px solid #f0f1f4;
    border-left: none;
    border-right: none;
    & > div {
      flex: 1;
      color: #333333;
      font-size: 28px;
      height: 88px;
      line-height: 88px;
      text-align: center;
      position: relative;
    }
    .active {
      color: #2d61f7;
      font-weight: bold;
      .tab-line {
        width: 60px;
        height: 4px;
        background: #4b71ff;
        border-radius: 2px;
        position: absolute;
        bottom: 10px;
        left: 50%;
        margin-left: -30px;
      }
    }
  }
  .Railway-contant,
  .Highway-contant {
    width: 93.6%;
    margin: 0 auto;
    font-size: 28px;
    font-weight: 500;
    .cell {
      display: flex;
      align-items: center;
      height: 88px;
      line-height: 88px;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid #f0f1f4;
      .label,
      .value {
        color: #999999;
        font-size: 28px;
        height: 88px;
        line-height: 88px;
      }
    }
    .a-link {
      color: #327aff;
      font-size: 28px;
      margin-top: 24px;
    }
  }
  .search-btn {
    margin: 0 auto;
    margin-top: 50px;
    width: 500px;
    height: 80px;
    background: linear-gradient(0deg, #367bf8 0%, #6bb1ff 100%);
    border-radius: 40px;
    color: #ffffff;
    font-size: 26px;
    line-height: 80px;
    text-align: center;
  }
  .tips {
    width: 702px;
    background: #f8f8f8;
    border-radius: 10px;
    padding: 28px;
    margin: 50px auto 0;
    text-align: center;
    .tips-title {
      font-size: 40px;
      font-weight: bold;
      color: #666666;
    }
    span {
      color: #666666;
      font-size: 26px;
      margin-top: 20px;
    }
  }
}
</style>
